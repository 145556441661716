import { useClaimCheck } from "hooks/useClaimCheck";
import { usePageHeader } from "hooks/usePageHeader";
import { useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";
import { CollectionGatedContentPageStripeSection } from "./CollectionGatedContentPageStripeSection";
import { useParams } from "hooks/useParams";

export const CollectionGatedContentPage = () => {
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const { t } = useTranslation();
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    usePageHeader({
        title: t("collection-page:collections-settings-gated-content"),
        subTitle: t("page-titles:subheaders:gated-content"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("collection-page:collections-settings-gated-content")
        ]
    });

    return (
        <>
            {hasGatedContentAccess ? (
                <CollectionGatedContentPageStripeSection
                    videoPlayerId={videoPlayerId}
                />
            ) : (
                <StudioUpgradeCta />
            )}
        </>
    );
};
