import React, { useState } from "react";
import { Tutorial } from "../Tutorial";
import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { DeviceType, Language } from "@switcherstudio/switcher-api-client";
import { TutorialVideoType } from "../video/TutorialVideo/types";
import { Row } from "components/layout/Row";

interface TutorialListProps {
    isMobile?: boolean;
    isHome?: boolean;
    hideViewAll?: boolean;
    limit?: number;
    offset?: number;
}

export const TutorialList: React.FC<TutorialListProps> = ({
    isMobile,
    isHome = false,
    hideViewAll = false,
    limit,
    offset = 0
}) => {
    const { t } = useTranslation();
    SwiperCore.use([Navigation, A11y]);
    const [tutorials, setTutorials] = useState<TutorialVideoType[]>(null);

    const { loading } = useSwitcherClient(
        (client) => client.tutorial_GetTutorialLibrary,
        {
            requestImmediately: true,
            args: ["en" as Language, "iPad" as DeviceType],
            onSuccess: (data) => {
                setTutorials(
                    (isHome ? data.Home : data.All?.[0]).Content.map(
                        (c: any) => {
                            return {
                                id: c.Id,
                                name: c.Title,
                                url: c.Assets[0].Url,
                                thumbnailUrl: c.Assets[0].ThumbnailUrl
                            } as TutorialVideoType;
                        }
                    )
                );
            }
        }
    );

    if (!tutorials || loading) return <></>;

    return (
        <>
            <Row
                className="w-100"
                header={!!limit ? t("page-titles:video-tutorials") : null}
            >
                {isMobile ? (
                    <div className={styles["tutorials-container"]}>
                        <Swiper
                            onSwiper={(swiper) =>
                                ((window as any).swiper = swiper)
                            }
                            spaceBetween={25}
                            slidesPerView={1}
                            navigation
                            a11y={{
                                prevSlideMessage: "Previous Video",
                                nextSlideMessage: "Next Video"
                            }}
                        >
                            {tutorials.map((v) => {
                                return (
                                    <SwiperSlide key={v.id}>
                                        <Tutorial
                                            video={v}
                                            key={v.id}
                                            isMobile={isMobile}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                ) : (
                    <>
                        {tutorials
                            .slice(offset, limit ? limit + offset : undefined)
                            .map((v) => (
                                <Tutorial
                                    isMobile={isMobile}
                                    key={v.id}
                                    video={v}
                                />
                            ))}
                    </>
                )}
            </Row>
            {limit && !hideViewAll && (
                <div className={styles["button-container"]}>
                    <Link to="/tutorials" className="btn btn-primary">
                        {t("buttons:view-all")}
                    </Link>
                </div>
            )}
        </>
    );
};
