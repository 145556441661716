import {
    Broadcast,
    BroadcastStatus,
    BroadcastWebLink,
    Category,
    CreatorProductEntitlement,
    CloudflareVideo,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import { BroadcastDetailsForm } from "components/forms/BroadcastDetailsForm";
import { ModalBase } from "components/modal/ModalBase";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { closeCurrentModal } from "store/modal/slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import rollbar from "helpers/rollbar";
import { setLoading } from "store/loading/slice";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { client } from "api/client";
import { AttentionModal } from "../AttentionModal";
import { AspectRatio } from "views/page-content/cloud/upload/types";

export interface EditBroadcastModalProps {
    video: CloudflareVideo;
    broadcast: Broadcast;
    onBroadcastUpdate: () => void;
    players?: VideoPlayer[];
    entitlements?: CreatorProductEntitlement[];
}

export const EditBroadcastModal = ({
    video,
    broadcast,
    onBroadcastUpdate,
    players,
    entitlements
}: EditBroadcastModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [title, setTitle] = useState<string>(broadcast?.Title);
    const [description, setDescription] = useState<string>(
        broadcast?.Description
    );
    const [thumbnailFile, setThumbnailFile] = useState<File>();
    const [thumbnailImageURL, setThumbnailImageURL] = useState<string>(
        broadcast?.ThumbnailAsset?.SignedUrl
    );
    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>(
        broadcast?.Categories ?? []
    );
    const [showInCatalog, setShowInCatalog] = useState<boolean>(
        broadcast?.ShowInCatalog
    );
    const [isAttentionModalOpen, setIsAttentionModalOpen] =
        useState<boolean>(false);

    const existingPlayers = useMemo<string[]>(
        () => players?.map((p) => p.Id),
        [players]
    );
    const isLive = useMemo<boolean>(() => {
        return (
            (broadcast.InputId !== null && broadcast.ActiveAt === null) ||
            broadcast.EndedAt === null
        );
    }, [broadcast.ActiveAt, broadcast.EndedAt, broadcast.InputId]);

    const [addToPlayers, setAddToPlayers] = useState<string[]>(existingPlayers);
    const [links, setLinks] = useState<BroadcastWebLink[]>([]);
    const [broadcastStatus, setBroadcastStatus] = useState<BroadcastStatus>(
        broadcast?.BroadcastStatus
    );
    const [broadcastStartsAt, setBroadcastStartsAt] = useState<string>(
        broadcast?.StartsAt
    );

    const { dispatchApiRequest: putBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_PutBroadcast,
        {
            onSuccess: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("playlist-page:edit-success")
                    })
                );
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("playlist-page:edit-error")
                    })
                );
            }
        }
    );

    const { dispatchApiRequest: getBroadcastWebLinks } = useSwitcherClient(
        (client) => client.webLink_GetBroadcastWebLinks
    );

    const { dispatchApiRequest: addToPlayer } = useSwitcherClient(
        (client) => client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcast
    );

    const { dispatchApiRequest: removeFromPlayer } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_DeleteVideoPlayerPlaylistBroadcast
    );

    const { handleUpdateThumbnail, handleDeleteThumbnail } = useThumbnailUpload(
        thumbnailFile,
        thumbnailImageURL
    );

    const fetchLinks = useCallback(async () => {
        try {
            const response = await getBroadcastWebLinks([[broadcast.Id]]);
            setLinks(response);
        } catch (e) {
            rollbar.error(e);
        }
    }, [broadcast.Id, getBroadcastWebLinks]);

    useEffect(() => {
        fetchLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            dispatch(setLoading(1));
            setIsAttentionModalOpen(false);
            dispatch(closeCurrentModal());
            let updatedBroadcast: Broadcast = {
                ...broadcast,
                Title: title,
                Description: description,
                Categories: categories,
                ShowInCatalog: showInCatalog,
                BroadcastStatus: broadcastStatus,
                StartsAt: broadcastStartsAt
                    ? new Date(broadcastStartsAt).toISOString()
                    : new Date().toISOString(),
                ...(broadcastStartsAt
                    ? { ActiveAt: new Date(broadcastStartsAt).toISOString() }
                    : { ActiveAt: new Date().toISOString() }),
                ...(broadcastStartsAt && video?.duration
                    ? {
                          EndedAt: new Date(
                              Math.round(
                                  (new Date(broadcastStartsAt).getTime() +
                                      video?.duration * 1000) /
                                      1000
                              ) * 1000
                          ).toISOString()
                      }
                    : video?.duration && {
                          EndedAt: new Date(
                              Math.round(
                                  (new Date().getTime() +
                                      video?.duration * 1000) /
                                      1000
                              ) * 1000
                          ).toISOString()
                      })
            };

            if (thumbnailFile) {
                const thumbnail = await handleUpdateThumbnail(broadcast);
                updatedBroadcast.ThumbnailAssetId = thumbnail.Id;
                updatedBroadcast.ThumbnailAsset = thumbnail;
            } else if (thumbnailRemoved) {
                handleDeleteThumbnail(broadcast);
                updatedBroadcast.ThumbnailAsset = undefined;
                updatedBroadcast.ThumbnailAssetId = undefined;
            }

            if (!!addToPlayers) {
                for (const player of addToPlayers) {
                    if (!existingPlayers.includes(player)) {
                        const playerResponse =
                            await client.videoPlayers_GetVideoPlayer(player);
                        const playlist =
                            playerResponse.VideoPlayer.VideoPlayerPlaylists[0];
                        await addToPlayer([
                            playlist.Id,
                            {
                                PlaylistBroadcast: {
                                    BroadcastId: broadcast.Id,
                                    VideoPlayerPlaylistId: playlist.Id
                                }
                            }
                        ]);
                    }
                }
            }

            if (!!existingPlayers) {
                for (const player of existingPlayers) {
                    if (!addToPlayers.includes(player)) {
                        const playerResponse =
                            await client.videoPlayers_GetVideoPlayer(player);
                        const playlist =
                            playerResponse.VideoPlayer.VideoPlayerPlaylists[0];
                        await removeFromPlayer([
                            playlist.Id,
                            playlist.VideoPlayerPlaylistBroadcasts.find(
                                (pb) => pb.BroadcastId === broadcast.Id
                            )
                        ]);
                    }
                }
            }

            await putBroadcast([broadcast.Id, updatedBroadcast, false, false]);
            onBroadcastUpdate && onBroadcastUpdate();
        } catch (e) {
            rollbar.error("Error editing broadcast", e);
        } finally {
            dispatch(setLoading(-1));
        }
    }, [
        dispatch,
        broadcast,
        title,
        description,
        categories,
        showInCatalog,
        broadcastStatus,
        broadcastStartsAt,
        video?.duration,
        thumbnailFile,
        thumbnailRemoved,
        addToPlayers,
        existingPlayers,
        putBroadcast,
        onBroadcastUpdate,
        handleUpdateThumbnail,
        handleDeleteThumbnail,
        addToPlayer,
        removeFromPlayer
    ]);

    // If the broadcast status has changed and the broadcast
    // has entitlements, show the attention modal.
    const checkStatusAndEntitlement = useCallback(async () => {
        if (
            broadcastStatus === BroadcastStatus._5 &&
            broadcastStatus !== broadcast.BroadcastStatus &&
            entitlements?.length > 0
        ) {
            setIsAttentionModalOpen(true);
            return;
        }

        await handleSubmit();
    }, [broadcast, broadcastStatus, entitlements, handleSubmit]);

    if (!broadcast) {
        <></>;
    }

    return (
        <>
            <ModalBase
                isOpen={true}
                successButton={t("playlist-page:submit-edit")}
                onSuccess={checkStatusAndEntitlement}
                dismissButton={t("playlist-page:edit-cancel")}
                onDismiss={() => dispatch(closeCurrentModal())}
                header={t("playlist-page:edit-broadcast-modal")}
                shouldNotCloseOnSuccess={true}
                preventDismiss={isAttentionModalOpen}
            >
                <BroadcastDetailsForm
                    broadcastId={broadcast.Id}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    setThumbnailFile={setThumbnailFile}
                    thumbnailImageURL={thumbnailImageURL}
                    setThumbnailImageURL={setThumbnailImageURL}
                    onRemoveThumbnail={() => setThumbnailRemoved(true)}
                    selectedCategories={categories}
                    setSelectedCategories={setCategories}
                    broadcastStatus={broadcastStatus}
                    setBroadcastStatus={setBroadcastStatus}
                    startsAt={broadcastStartsAt}
                    setStartsAt={setBroadcastStartsAt}
                    players={addToPlayers}
                    links={links}
                    onLinkSave={fetchLinks}
                    setAddToPlayers={setAddToPlayers}
                    liveBroadcast={isLive}
                    showThumbnailUploader
                    showCategories
                    showInCatalog={showInCatalog}
                    setShowInCatalog={setShowInCatalog}
                    isUpload={false}
                    orientation={
                        video?.input?.width >= video?.input?.height
                            ? AspectRatio.horizontal
                            : AspectRatio.vertical
                    }
                />
            </ModalBase>
            <AttentionModal
                isOpen={isAttentionModalOpen}
                setIsOpen={setIsAttentionModalOpen}
                handleCancel={() => setIsAttentionModalOpen(false)}
                hasContinueButton={true}
                handleContinue={() => handleSubmit()}
            >
                {t("playlist-page:unpublish-entitlement-warning")}
            </AttentionModal>
        </>
    );
};
