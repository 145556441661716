import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../helpers/analyticsHelpers";

// icons
import CloudIcon from "assets/icons/cloud.svg?react";
import BillingIcon from "assets/icons/billing.svg?react";
import FilmStripIcon from "assets/icons/film-strip.svg?react";
import SwitcherPlayerIcon from "assets/icons/switcher-player-rev.svg?react";
import PlatformIcon from "assets/icons/platforms.svg?react";
import ProfileIcon from "assets/icons/username.svg?react";
import HelpIcon from "assets/icons/question-mark.svg?react";
import HistoryIcon from "assets/icons/history.svg?react";
import GatedIcon from "assets/icons/gated.svg?react";
import MessageIcon from "assets/icons/message.svg?react";
import AppCenterIcon from "assets/icons/appcenter.svg?react";
import CloseIcon from "assets/icons/close.svg?react";
import RemoteGuestsIcon from "assets/icons/guests.svg?react";
import LogoutIcon from "assets/icons/logout.svg?react";
import WorkspaceIcon from "assets/icons/workspace.svg?react";
import TeamIcon from "assets/icons/user-group.svg?react";
import BetaIcon from "assets/icons/beta.svg?react";
import LivestreamsIcon from "assets/icons/livestreams.svg?react";
import BrandKitIcon from "assets/icons/brandkit.svg?react";
import AnalyticsIcon from "assets/icons/analytics.svg?react";
import TutorialIcon from "assets/icons/tutorials.svg?react";
import styles from "./Sidebar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { logout } from "store/user/slice";
import { resetNotifications } from "store/notification/slice";
import { RootState } from "store/reducers";
import { FlagContainer } from "components/flag-container/FlagContainer";
import {
    mustBeOrgAdmin,
    mustBeSubscriptionOwner
} from "hooks/useRedirectIfDisallowed";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useSupportSystem } from "hooks/useSupportSystem";
import { resetEvents } from "store/events/slice";
import { resetApiCache } from "store/api/slice";
import { NavDropdown } from "components/navs/NavDropdown";
import {
    toggleSidebarDropdownSelection,
    setSidebarDropdownSelection
} from "store/view/slice";
import { BookingButton } from "components/buttons/BookingButton";

// Import the logos and set Logo based on the environment variable
import Logo from "assets/icons/switcher-horizontal_new.svg?url";
import { useNavigate } from "react-router-dom";

interface SidebarProps {
    pathname: string;
    show: boolean;
    onToggle: () => void;
}
export const Sidebar: React.FC<SidebarProps> = ({
    pathname,
    show,
    onToggle
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const user = useSelector((state: RootState) => state.user);
    const { isSupportAvailable, supportSystemCommand } = useSupportSystem();
    const { t } = useTranslation("page-titles");
    const enableCatalog = useClaimCheck("catalog");

    const activeRoute = useMemo<string>(
        () => `/${pathname.split("/")[1]}`,
        [pathname]
    );

    const nav = useMemo(() => {
        return {
            videoCMS: [
                {
                    title: t("page-titles:video-library"),
                    path: "/video-library",
                    icon: FilmStripIcon
                },
                ...(enableCatalog
                    ? [
                          {
                              title: t("page-titles:catalog"),
                              path: "/catalog",
                              icon: SwitcherPlayerIcon
                          }
                      ]
                    : []),
                {
                    title: t("page-titles:gated-content"),
                    path: "/gated-content",
                    icon: GatedIcon
                },
                {
                    title: t("page-titles:analytics"),
                    path: "/analytics",
                    icon: AnalyticsIcon
                }
            ],
            creationTools: [
                {
                    title: t("page-titles:downloads"),
                    path: "/downloads",
                    icon: AppCenterIcon
                },
                {
                    title: t("page-titles:platforms"),
                    path: "/platforms",
                    icon: PlatformIcon
                },
                {
                    title: t("page-titles:switcher-cloud"),
                    path: "/switcher-cloud",
                    icon: CloudIcon
                },
                {
                    title: t("page-titles:remote-guests"),
                    path: "/remote-guests",
                    icon: RemoteGuestsIcon
                },
                {
                    title: t("page-titles:my-livestreams"),
                    path: "/my-livestreams",
                    icon: LivestreamsIcon
                },
                ...(mustBeSubscriptionOwner(user)
                    ? [
                          {
                              title: t("page-titles:experimental-features"),
                              path: "/experimental-features",
                              icon: BetaIcon
                          }
                      ]
                    : [])
            ],
            accountSettings: [
                ...(mustBeSubscriptionOwner(user)
                    ? [
                          {
                              title: t("page-titles:subscription"),
                              path: "/subscription",
                              icon: BillingIcon
                          }
                      ]
                    : []),
                {
                    title: t("page-titles:profile"),
                    path: "/profile",
                    icon: ProfileIcon
                },
                {
                    title: t("page-titles:brand-profile"),
                    path: "/brand-profile",
                    icon: BrandKitIcon
                },
                ...(user.orgs.length > 0
                    ? [
                          {
                              title: t("page-titles:workspaces"),
                              path: "/workspaces",
                              icon: WorkspaceIcon
                          }
                      ]
                    : []),
                ...(mustBeOrgAdmin(user)
                    ? [
                          {
                              title: t("page-titles:my-team"),
                              path: "/my-team",
                              icon: TeamIcon
                          }
                      ]
                    : [])
            ],
            help: [
                {
                    title: t("page-titles:tutorials"),
                    path: "/tutorials",
                    icon: TutorialIcon
                },
                {
                    title: t("page-titles:help-center"),
                    path: "/help",
                    action: () => {
                        window.open(
                            "https://support.switcherstudio.com",
                            "_blank"
                        );
                    },
                    icon: HelpIcon
                },
                {
                    title: t("page-titles:contact-support"),
                    path: "/contact-support",
                    action: () => {
                        if (isSupportAvailable) {
                            trackEvent("Opened Support Widget", {
                                source: "sidebar"
                            });
                            supportSystemCommand("show");
                        } else {
                            window.open("mailto:support@switcherstudio.com");
                        }
                    },
                    icon: MessageIcon
                },
                ...(isSupportAvailable
                    ? [
                          {
                              title: t("page-titles:support-messages"),
                              path: "/support-messages",
                              action: () => {
                                  supportSystemCommand("showMessages");
                              },
                              icon: HistoryIcon
                          }
                      ]
                    : [])
            ]
        };
    }, [t, user, isSupportAvailable, supportSystemCommand, enableCatalog]);

    const {
        sidebarDropdownSelection: {
            videoCMS = null,
            accountSettings = null,
            creationTools = null,
            help = null
        }
    } = useSelector((state: RootState) => state.view);

    const isVideoCMSPath = nav.videoCMS.some((navItem) =>
        pathname.startsWith(navItem.path)
    );
    const isCreationToolsPath = nav.creationTools.some((navItem) =>
        pathname.startsWith(navItem.path)
    );
    const isaccountSettingsRoutesPath = nav.accountSettings.some((navItem) =>
        pathname.startsWith(navItem.path)
    );
    const ishelpRoutesPath = nav.help.some((navItem) =>
        pathname.startsWith(navItem.path)
    );

    const showBookTime = useMemo<boolean>(() => {
        return (
            user?.userInfo?.Status === "trialing" &&
            user?.userInfo?.Roles?.includes("Professional")
        );
    }, [user]);

    useEffect(() => {
        if (isVideoCMSPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "videoCMS",
                    value: isVideoCMSPath
                })
            );
        }
        if (isCreationToolsPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "creationTools",
                    value: isCreationToolsPath
                })
            );
        }
        if (ishelpRoutesPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "help",
                    value: ishelpRoutesPath
                })
            );
        }
        if (isaccountSettingsRoutesPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "accountSettings",
                    value: isaccountSettingsRoutesPath
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${styles.sidebar} ${show ? styles.active : ""}`}>
                <span className={styles.close} onClick={onToggle}>
                    <CloseIcon />
                </span>
                <div className={styles.container}>
                    <div className={styles["sidebar-top"]}>
                        <div
                            className={`${styles["desktop-logo"]} text-center`}
                        >
                            <a
                                href="/home"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/home");
                                }}
                            >
                                <img src={Logo} alt="Switcher Logo" />
                            </a>
                        </div>
                        <div className={styles["sidebar-dropdown-container"]}>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:videoCMS"
                                ).toUpperCase()}
                                navItems={nav.videoCMS}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={videoCMS}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "videoCMS"
                                        )
                                    );
                                }}
                            ></NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:creationTools"
                                ).toUpperCase()}
                                navItems={nav.creationTools}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={creationTools}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "creationTools"
                                        )
                                    );
                                }}
                            ></NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:accountSettings"
                                ).toUpperCase()}
                                navItems={nav.accountSettings}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={accountSettings}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "accountSettings"
                                        )
                                    );
                                }}
                            ></NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:help"
                                ).toUpperCase()}
                                navItems={nav.help}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={help}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection("help")
                                    );
                                }}
                            ></NavDropdown>
                        </div>
                        {showBookTime && <BookingButton />}
                    </div>
                    <div className={`${styles["sidebar-bottom"]}`}>
                        <div className={styles["user-info"]}>
                            <div className={styles["user-org"]}>
                                {user?.userInfo?.Email}
                            </div>
                            <div className={styles["user-plan"]}>
                                {user?.userInfo?.PlanName}
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    supportSystemCommand("shutdown");
                                    navigate("/login");
                                    dispatch(resetNotifications());
                                    dispatch(resetApiCache());
                                    dispatch(resetEvents());
                                    dispatch(logout());
                                }}
                            >
                                {<LogoutIcon />}{" "}
                                {t("page-titles:logout").toUpperCase()}
                            </button>
                        </div>
                        <FlagContainer />
                    </div>
                </div>
            </div>
            <div
                className={`${styles.backdrop} ${show ? styles.active : ""}`}
                onClick={onToggle}
            ></div>
        </>
    );
};
