import { createBrowserRouter } from "react-router-dom";
import { BaseLayout } from "views/BaseLayout";
import { DashboardLayout } from "views/DashboardLayout";
import { LoginPage } from "views/login/LoginPage";
import { HomePage } from "views/page-content/home/HomePage";
import { redirectTo, windowOpen } from "./loaders";
import { VideoLibraryPage } from "views/page-content/video-library";
import { CatalogRoutes } from "./Routes/catalogRoutes";
import { GatedContentPage } from "views/page-content/gated-content";
import { AnalyticsPage } from "views/page-content/analytics/AnalyticsPage";
import { TutorialsPage } from "views/page-content/tutorials/TutorialsPage";
import { DownloadsPage } from "views/page-content/downloads/DownloadsPage";
import { PlatformRoutes } from "./Routes/platformRoutes";
import { CloudPage } from "views/page-content/cloud/CloudPage";
import { UploadAssetPage } from "views/page-content/cloud/upload/UploadAssetPage";
import { RemoteGuestsPage } from "views/page-content/remote-guests/RemoteGuestsPage";
import { MyLivestreamsPage } from "views/page-content/my-livestreams/MyLivestreamsPage";
import { ExperimentalFeaturesPage } from "views/page-content/experimental-features/ExperimentalFeaturesPage";
import { ProfilePage } from "views/page-content/profile/ProfilePage";
import { BrandProfilePage } from "views/page-content/brand-profile/BrandProfile";
import { SubscriptionRoutes } from "./Routes/subscriptionRoutes";
import { ForgotPasswordPage } from "views/login/ForgotPasswordPage";
import { ManageInventoriesPage } from "views/page-content/manage-inventories/ManageInventoriesPage";
import { WorkspacesPage } from "views/page-content/workspaces/WorkspacesPage";
import { MyTeamPage } from "views/page-content/my-team/MyTeamPage";
import { DownloadsStandalone } from "views/page-content/downloads/DownloadsStandalone";
import { AccountDeletionPage } from "views/page-content/account-deletion/AccountDeletionPage";
import { DevicePreviewPage } from "views/page-content/DevicePreviewPage";
import { AssetDetailsPage } from "views/page-content/cloud/asset-details/AssetDetailsPage";
import { RawFileDetailsPage } from "views/page-content/cloud/raw-file-details/RawFileDetailsPage";
import { ManageInventoryItemsPage } from "views/page-content/manage-inventories/ManageInventoryItemsPage";
import { ResetPasswordPage } from "views/login/ResetPasswordPage";
import { ConfirmEmailPage } from "views/login/ConfirmEmailPage";
import { LinkAccountPage } from "views/page-content/link-account/LinkAccountPage";
import { SpeedTestPage } from "views/page-content/speed-test/SpeedTestPage";
import { AuthorizePage } from "views/authorize/AuthorizePage";
import { OnboardingWrapper } from "views/page-content/onboarding/OnboardingWrapper";
import { NotFound } from "views/not-found";

const videoCMSRoutes = [
    {
        path: "videos",
        loader: redirectTo("/video-library")
    },
    {
        path: "video-library",
        element: <VideoLibraryPage />
    },
    {
        path: "gated-content",
        element: <GatedContentPage />
    },
    {
        path: "analytics",
        element: <AnalyticsPage />
    },
    ...CatalogRoutes
];

const creationToolsRoutes = [
    {
        path: "downloads",
        element: <DownloadsPage />
    },
    {
        path: "cloud",
        loader: redirectTo("/switcher-cloud")
    },
    {
        path: "cloud/upload",
        loader: redirectTo("/switcher-cloud/upload")
    },
    {
        path: "switcher-cloud",
        element: <CloudPage />
    },
    {
        path: "switcher-cloud/asset/:assetId",
        element: <AssetDetailsPage />
    },
    {
        path: "switcher-cloud/raw-file/:assetId",
        element: <RawFileDetailsPage />
    },
    {
        path: "switcher-cloud/upload",
        element: <UploadAssetPage />
    },
    {
        path: "videochat",
        loader: redirectTo("/remote-guests")
    },
    {
        path: "video-chat",
        loader: redirectTo("/remote-guests")
    },
    {
        path: "vc",
        loader: redirectTo("https://videochat.switcherstudio.com")
    },
    {
        path: "vc/:roomId",
        loader: redirectTo("https://videochat.switcherstudio.com/:roomId")
    },
    {
        path: "remote-guests",
        element: <RemoteGuestsPage />
    },
    {
        path: "my-livestreams",
        element: <MyLivestreamsPage />
    },
    {
        path: "experimental-features",
        element: <ExperimentalFeaturesPage />
    },
    {
        path: "experimental-features/:featureId",
        element: <ExperimentalFeaturesPage />
    },
    {
        path: "experimentalfeatures",
        loader: redirectTo("/experimental-features")
    },
    {
        path: "experimentalfeatures/:featureId",
        loader: redirectTo("/experimental-features/:featureId")
    },
    ...PlatformRoutes
];

const accountSettingsRoutes = [
    ...SubscriptionRoutes,
    {
        path: "profile",
        element: <ProfilePage />
    },
    {
        path: "brand-profile",
        element: <BrandProfilePage />
    },
    {
        path: ".well-known/change-password",
        loader: redirectTo("/profile")
    }
];

const helpRoutes = [
    {
        path: "tutorials",
        element: <TutorialsPage />
    },
    {
        path: "help",
        loader: windowOpen("https://support.switcherstudio.com")
    }
];

const gettingStartedRoutes = [
    {
        path: "register",
        loader: redirectTo("/getting-started")
    },
    {
        path: "register-external",
        loader: redirectTo("/getting-started")
    },
    {
        path: "create-account",
        loader: redirectTo("/getting-started")
    }
];

const BrowserRouter = () => {
    return createBrowserRouter([
        {
            path: "/",
            element: <BaseLayout />,
            children: [
                {
                    path: "",
                    loader: redirectTo("/home")
                },
                {
                    path: "login",
                    element: <LoginPage />
                },
                {
                    path: "forgot-password",
                    element: <ForgotPasswordPage />
                },
                {
                    path: "forgotpassword",
                    loader: redirectTo("/forgot-password")
                },
                {
                    path: "invitation",
                    element: <ResetPasswordPage isInvitation={true} />
                },
                {
                    path: "reset-password",
                    element: <ResetPasswordPage />
                },
                {
                    path: "resetpassword",
                    loader: redirectTo("/reset-password")
                },
                {
                    path: "confirmemail",
                    element: <ConfirmEmailPage />
                },
                {
                    path: "authorize",
                    element: <AuthorizePage />
                }
            ]
        },
        {
            path: "getting-started",
            element: <OnboardingWrapper />
        },
        {
            path: "device-preview",
            element: <DevicePreviewPage />
        },
        {
            path: "/",
            element: <DashboardLayout />,
            children: [
                {
                    path: "",
                    loader: redirectTo("/home")
                },
                {
                    path: "welcome",
                    loader: redirectTo("/home")
                },
                {
                    path: "home",
                    element: <HomePage />
                },
                {
                    path: "reseller",
                    loader: redirectTo("/manage-inventories")
                },
                {
                    path: "reseller/inventories",
                    loader: redirectTo("/manage-inventories")
                },
                {
                    path: "reseller/inventories/:resellerInventoryId",
                    loader: redirectTo(
                        "/manage-inventories/:resellerInventoryId"
                    )
                },
                {
                    path: "manage-inventories",
                    element: <ManageInventoriesPage />
                },
                {
                    path: "manage-inventories/:resellerInventoryId",
                    element: <ManageInventoryItemsPage />
                },
                {
                    path: "workspaces",
                    element: <WorkspacesPage />
                },
                {
                    path: "my-team",
                    element: <MyTeamPage />
                },
                {
                    path: "download-switchercast",
                    element: <DownloadsStandalone />
                },
                {
                    path: "account-deletion",
                    element: <AccountDeletionPage />
                },
                {
                    path: "getStarted",
                    loader: redirectTo("/tutorials")
                },
                {
                    path: "linkAccount",
                    element: <LinkAccountPage />
                },
                {
                    path: "speedtest",
                    element: <SpeedTestPage />
                },
                ...gettingStartedRoutes,
                ...videoCMSRoutes,
                ...creationToolsRoutes,
                ...accountSettingsRoutes,
                ...helpRoutes,
                {
                    path: "*",
                    element: <NotFound />
                }
            ]
        }
    ]);
};
export default BrowserRouter;
