import React from "react";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { useClaimCheck } from "hooks/useClaimCheck";
import { CatalogSubscriptionPageStripeSection } from "./CatalogSubscriptionPageStripeSection";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";

export const CatalogSubscriptionPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("subscription-page");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    usePageHeader({
        title: t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("subscription-page:subscription-options")
        ],
        subTitle: (
            <Trans
                i18nKey={t("subscription-page:subscription-options-subtitle")}
            />
        )
    });

    return (
        <>
            {hasGatedContentAccess ? (
                <CatalogSubscriptionPageStripeSection />
            ) : (
                <StudioUpgradeCta />
            )}
        </>
    );
};
