import { useState, useCallback, useEffect } from "react";
import { Asset } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { setLoading } from "store/loading/slice";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { openConfirmation } from "store/confirmation/slice";
import { AppDispatch } from "store/store";

export function useRawFileDetails(assetId: string) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { userInfo } = useSelector((s: RootState) => s.user);
    const [rawFile, setRawFile] = useState<Asset>();
    const [assetsUsingRawFile, setAssetsUsingRawFile] = useState<Asset[]>([]);
    const [disable, setDisable] = useState<boolean>(false);

    useEffect(() => {
        async function run() {
            try {
                dispatch(setLoading(1));
                const asset = await client.userAssets_GetUserAsset(
                    userInfo?.UserId || "",
                    assetId
                );

                if (asset.RequiredByAssetIds) {
                    let requiredByAssets = [];
                    for (const ra of asset?.RequiredByAssetIds) {
                        const requiredAsset =
                            await client.userAssets_GetUserAsset(
                                userInfo?.UserId || "",
                                ra
                            );

                        requiredByAssets.push(requiredAsset);
                    }

                    const assetsUseOtherFiles = requiredByAssets
                        .map((ra) => ra.RequiresAssetIds)
                        .some((ra) => ra?.length && ra.length > 1);

                    if (assetsUseOtherFiles) {
                        setDisable(assetsUseOtherFiles);
                    }

                    setAssetsUsingRawFile(requiredByAssets);
                }
                setRawFile(asset);
                dispatch(setLoading(-1));
            } catch (e) {
                rollbar.error("Error retrieving asset", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:asset-retrieval-error"
                    })
                );
                dispatch(setLoading(-1));
            }
        }

        run();
    }, [dispatch, assetId, userInfo, t]);

    const remove = useCallback(async () => {
        if (rawFile) {
            dispatch(
                openConfirmation({
                    htmlMessage: !!assetsUsingRawFile.length,
                    message: !!assetsUsingRawFile.length
                        ? `
          <p>${t("asset-hook:confirm-5")}</p>
          <p>${assetsUsingRawFile.map((a) => a.Name).join("<br/>    ")}</p>
          
          <p>${t("asset-hook:confirm-4")}</p>
                    `
                        : "messages:delete-raw-confirmation",
                    onSuccess: async () => {
                        try {
                            dispatch(setLoading(1));
                            await client.userAssets_DeleteUserAssets(
                                userInfo?.UserId || "",
                                [rawFile, ...assetsUsingRawFile]
                            );
                            dispatch(setLoading(-1));
                            navigate("/switcher-cloud");
                        } catch (e) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: "errors:asset-removal-error"
                                })
                            );
                            dispatch(setLoading(-1));
                        }
                    }
                })
            );
        }
    }, [dispatch, navigate, rawFile, assetsUsingRawFile, userInfo, t]);

    return {
        rawFile,
        assetsUsingRawFile,
        disable,
        remove
    };
}
