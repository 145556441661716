import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    useBeforeUnload as _useBeforeUnload,
    useBlocker
} from "react-router-dom";

export const useBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    const { t } = useTranslation();

    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (shouldRun) {
                if (confirmOnExit) {
                    event.preventDefault();
                    //NOTE: returnValue is deprectated, keeping it for support on older browsers.
                    event.returnValue = "";
                } else {
                    handler && handler();
                }
            }
        },
        [shouldRun, handler, confirmOnExit]
    );

    _useBeforeUnload(handleBeforeUnload);

    const blocker = useBlocker(shouldRun && confirmOnExit);

    useEffect(() => {
        if (blocker.state === "blocked") {
            const message = t("message:confirm-before-unload");
            const response = window.confirm(message);
            if (response) {
                handler && handler();
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocker.state, handler, t]);
};
